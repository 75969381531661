import React, { useEffect, useState } from 'react'
import './CarpoolingPart.css'
import { options } from '../../constants'
import { useDispatch } from 'react-redux'
import { showMobilePopUpAction } from '../../redux/action'

const CarpoolingPart = () => {
  const dispatch = useDispatch()
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='carpooling-part'>
      <div className='carpooling-content'>
        <div className='carpooling-content-title'>
          <span>{options?.[language]?.carPoolingTitle}</span>
        </div>
        <div className='carpooling-content-mini-desc'>
          <span>{options?.[language]?.carPoolingDesc}</span>
        </div>
        <div className='carpooling-content-cards'>
          {options?.[language]?.carPoolingCards?.map((el, index) => {
            return (
              <div key={index} className='carpooling-content-card'>
                <span
                  style={{
                    fontSize: '50px'
                  }}
                >
                  {el.icon}
                </span>
                <span className='carpooling-content-card-desc'>{el.desc}</span>
              </div>
            )
          })}
        </div>
        <div className='carpooling-content-devis'>
          <span>{options?.[language]?.carPoolingDesc2}</span>
        </div>
        <div className='carpooling-content-button'>
          <button style={{cursor:'pointer'}} onClick={() => dispatch(showMobilePopUpAction(true))}>{options?.[language]?.carPoolingButton}</button>
        </div>
      </div>
    </div>
  )
}

export default CarpoolingPart
