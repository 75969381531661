export const metaTagsValues = {
  AZ: {
    title: [
      'Gedis tap - Gezz.az',
      'Maşın icarəsi tap - Gezz.az',
      'Restoran və Kafe tap - Gezz.az',
      'Otel və Hostel tap - Gezz.az',
      'Fealiyyet və Eylence tap - Gezz.az'
    ],
    description: [
      'Getdiyin yere gedis tap - Gezz.az',
      'Getdiyin yerde maşın tap - Gezz.az',
      'Getdiyin yerde restoran, kafe tap - Gezz.az',
      'Getdiyin yerde otel tap - Gezz.az',
      'Getdiyin yerde fealiyyet ve eylence tap - Gezz.az'
    ],
    keywords: [
      'gedis, gelis, surucu, sürücü, seyahet, musteri, rayona taxi, rayona seyahet, gezinti, gezz, sofer, ucuz taksi, ucuz taxi, ucuz qiymete gezinti, ucuz qiymete seyahet, gəzməli rayonlar, gezmeli rayonlar, qobustana seyahet, bakiya seyahet, gence seyahet, baki gence, baki gence suret qatari, rayona avtobus, daga seyahet, zaqatala seyahet, gebele seyahet, seki seyahet, lenkaran seyahet, susaya seyahet, qarabaga seyahet, avtobus, birlikde yol, birlikde seyahet, dostlarla seyahet, kamp, karpulinq',
      'masin, maşın kirasi, avtomobil kirayesi, avtomobil icaresi, kiraye masin, kirayə maşın, kirayə avto, rayona masin kiraye, kiraye masin goturmek, sehere masın arenda, arenda masin',
      'restoran, kafe, kafe axtar, restoran axtar, etrafda restoran, etrafda kafe, yol ustu restoran, yol ustu kafe, ucuz restoran, serfeli restoran, ucuz kafe, serfeli kafe, dadli yemekler, ev yemekleri',
      'otel, hostel, yer, gecelik otel, gecelik hostel, serfeli otel, serfeli hostel, ucuz oteller, ucuz hostel, gunluk otel, gunluk hostel, etrafdaki oteller, etrafdaki hosteller',
      'fealiyet, eylence, aktiviti, istirahet, oyun, aile eylence, etrafda eylence, etrafda failiyet, hobbi'
    ]
  },
  EN: {
    title: [
      'Find trips - Gezz.az',
      'Find cars - Gezz.az',
      'Find restaurants and cafes - Gezz.az',
      'Find hotels and hostels - Gezz.az',
      'Find activities and entertainment - Gezz.az'
    ],
    description: [
      'Find a place where you go - Gezz.az',
      'Find cars wherever you go - Gezz.az',
      'Find restaurants and cafes wherever you go - Gezz.az',
      'Find a hotel wherever you go - Gezz.az',
      'Find activities and entertainment wherever you go - Gezz.az'
    ],
    keywords: [
      'trip, arrival, driver, driver, users, taxi to the region, travel to the region, travel, trip, driver, cheap taxi, cheap price travel, regions to visit, travel to Gobustan, rest travel, gence travel, Baku gence, Baku gence train, bus to district, mountain travel, Zagatala travel, Gebele travel, Seki travel, Lankaran travel, Susa travel, Karabaga travel, bus, road together, travel together, travel with friends, camping, carpooling',
      'car, car rental, car rental to the district, to rent a car, car rental in the city, ',
      'restaurant, cafe, cafe search, restaurant search, nearby restaurants, nearby cafes, over-the-road restaurants, over-the-road cafes, cheap restaurants, restaurants with a buffet, cheap cafes, cafes with a buffet, delicious meals, home meals',
      'hotel, hostel, place, nightly hotel, nightly hostel, serfeli hotel, serfeli hostel, cheap hotels, cheap hostel, daily hotel, daily hostel, hotels around, hostels around',
      'activity, entertainment, activity, leisure, game, family entertainment, entertainment around me, activity around me, hobby'
    ]
  },
  RU: {
    title: [
      'Найдите поездки - Gezz.az',
      'Найдите машины - Gezz.az',
      'Найдите рестораны и кафе - Gezz.az',
      'Найти отели и хостелы - Gezz.az',
      'Найдите активности и развлечения - Gezz.az'
    ],
    description: [
      'Найдите поездку, куда бы вы ни пошли - Gezz.az',
      'Находите машины, куда бы вы ни пошли - Gezz.az',
      'Находите рестораны и кафе, куда бы вы ни пошли - Gezz.az',
      'Найдите отель, куда бы вы ни пошли - Gezz.az',
      'Найдите занятия и развлечения, куда бы вы ни пошли - Gezz.az'
    ],
    keywords: [
      'отъезд, прибытие, водитель, путешествие, сбори, такси в регион, путешествие в регион, путешествие, поездка, водитель, дешевое такси, дешевое такси, путешествие по дешевой цене, регионы для посещения, путешествие в Гобустан, путешествие на отдых, путешествие в Генце, путешествие в Баку, поезд в Баку, автобус в район, горное путешествие, путешествие в Загаталу, путешествие в Гебеле, путешествие в Секи, путешествие в Лянкяран, путешествие в Суса, путешествие в Карабагу, автобус, дорога вместе, путешествие вместе, путешествия с друзьями, кемпинг, совместные поездки на автомобиле',
      'машина, аренда машины, аренда автомобиля, аренда авто, аренда машины в район, взять машину в аренду, аренда машины в город, аренда машины',
      'ресторан, кафе, поиск кафе, поиск ресторана, близлежащие рестораны, близлежащие кафе, рестораны за дорогой, кафе за дорогой, дешевые рестораны, рестораны со шведским столом, дешевые кафе, кафе со шведским столом, вкусная еда, домашняя еда',
      'гостиница, отель, общежитие, место, ночной отель, ночной хостел, отель дешевый, хостел дешевый, дешевые отели, дешевый хостел, ежедневный отель, ежедневный хостел, отели вокруг, хостелы вокруг',
      'деятельность, развлечение, деятельность, досуг, игра, семейные развлечения, развлечения вокруг меня, деятельность вокруг меня, хобби'
    ]
  }
}
