import React, { useEffect, useState } from 'react'
import Searchbar from '../Components/Searchbar/Searchbar'
// import InfoPart from '../Components/InfoPart/InfoPart'
// import InfoPartPhone from '../Components/InfoPartPhone/InfoPartPhone'
import PopupMobile from '../Components/PopupM/PopupMobile'
import AppDownloadPopup from '../Components/AppDownloadPopup/AppDownloadPopup'
import Header from '../Components/Header/Header'
import DivisPart from '../Components/DivisPart/DivisPart'
import AdvantagesPart from '../Components/AdvantagesPart/AdvantagesPart'
import CounterPart from '../Components/CounterPart/CounterPart'
import HowItsWork from '../Components/HowItsWork/HowItsWork'
import CarpoolingPart from '../Components/CarpoolingPart/CarpoolingPart'
import FeedbackPart from '../Components/FeedbackPart/FeedbackPart'
import SecurityPart from '../Components/SecurityPart/SecurityPart'
import CardsPart from '../Components/CardsPart/CardsPart'
import Footer from '../Components/Footer/Footer'
import { useSelector } from 'react-redux'
import { metaTagsValues } from '../metaTags'

const Home = () => {
  const types = ['gedisler', 'masinlar', 'restoranlar', 'oteller', 'fealiyyetler']
  const showMobilePopUp = useSelector(state => state.showMobilePopUp)
  const searchType = useSelector(state => state.searchType)
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    if (types.includes(window.location.pathname.split('/')[1])) {
      let lng = window.location.pathname.split('/')[2]?.toUpperCase()
      let typeİndex = types.findIndex(
        type => type === window.location.pathname.split('/')[1]
      )
      let ogTitle = document.getElementById('ogTitle')
      let ogDescription = document.getElementById('ogDescription')
      ogTitle.setAttribute('content', metaTagsValues[lng].title[typeİndex])
      ogDescription.setAttribute(
        'content',
        metaTagsValues[lng].description[typeİndex]
      )
      document.title = metaTagsValues[lng].title[typeİndex]
      document
        .getElementsByTagName('meta')
        .namedItem('description')
        .setAttribute('content', metaTagsValues[lng].description[typeİndex])
      document
        .getElementsByTagName('meta')
        .namedItem('keywords')
        .setAttribute('content', metaTagsValues[lng].keywords[typeİndex])
    }
  }, [window.location.pathname])
  return (
    <div className='home'>
      <Header />
      <Searchbar />
      {searchType !== 0 && searchType !== 1 && <CardsPart searchType={searchType} />}
      {(searchType === 0 || searchType === '0' || searchType === '1' || searchType === 1 ) && (
        <>
          <DivisPart />
          <AdvantagesPart />
          <CounterPart />
          <HowItsWork />
          <CarpoolingPart />
          <SecurityPart />
          <FeedbackPart />
        </>
      )}
      {/* <InfoPart/>
      <InfoPartPhone/> */}
      <PopupMobile />
      {showMobilePopUp === true && <AppDownloadPopup />}
      <Footer />
    </div>
  )
}

export default Home
