import React, { useEffect, useState } from 'react'
import './CardsPart.css'
import { options } from '../../constants'
import ImageGallery from 'react-image-gallery'
import star from './images/star.svg'
import phone from './images/phone.svg'
import road from './images/road.svg'

const Card = ({ cardData }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  const [isHover, setIsHover] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  return (
    <div
      className='card'
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {/* <img width={80} height={80} src={cardData.photos[0]} alt={cardData.name}/> */}
      <ImageGallery
        items={cardData.photos?.map((el, index) => {
          return {
            original: el,
            renderItem: () => (
              <img
                src={el}
                alt={cardData.name}
                className='image-gallery-image'
              />
            )
          }
        })}
        showFullscreenButton={false}
        showBullets={true}
        showPlayButton={false}
        disableKeyDown={true}
        showNav={isHover}
        lazyLoad={true}
      />
      <div className='card-infopart'>
        <div className='card-infopart-row first-part-row'>
          <span className='card-name'>{cardData.name}</span>
          <span className='card-city'>
            {options[language].cities[cardData.city]}
          </span>
        </div>
        <div
          className='card-infopart-row'
          style={{
            marginTop: '2px'
          }}
        >
          <div className='card-rates-part'>
            <span className='card-rate'>
              {cardData.rate}
              <img src={star} alt='star' />
            </span>

            <span className='card-rate-count'>
              ({cardData.rateCount} {options[language].comment})
            </span>
          </div>
        </div>
        <div
          className='card-infopart-row card-infopart-row-bottom-part'
          style={{
            marginTop: 'auto'
          }}
        >
          {cardData.phone !== '+' ? (
            <span className='card-phone'>
              <a href={`tel:${cardData.phone}`} rel='nofollow'>
                <img src={phone} alt='phone' />
              </a>
              <a href={`tel:${cardData.phone}`} rel='nofollow'>
                {cardData.phone.replace(
                  /(\+994)(\d{2})(\d{3})(\d{2})(\d{2})/,
                  '$1($2) $3 $4 $5'
                )}
              </a>
            </span>
          ) : (
            <span></span>
          )}
          <a
            href={`https://www.google.com/maps/place/${cardData.latitude},${cardData.longitude}`}
            rel='nofollow'
            target='_blank'
            className='road-a-tag'
          >
            <button className='card-button'>
              <img src={road} alt='road' />
              <span style={{ marginTop: '2px' }}>{options[language].road}</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Card
