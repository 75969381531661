import React, { useEffect, useState } from 'react'
import './DivisPart.css'
import phoneImage from './phoneImage.png'
import { options } from '../../constants'
import { useDispatch } from 'react-redux'
import { showMobilePopUpAction } from '../../redux/action'

const DivisPart = () => {
  const [showedCount, setShowedCount] = useState(true)
  const dispatch = useDispatch()
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='divis-part'>
      <div className='divis-content'>
        <div className='divis-content-left'>
          <span className='divis-content-divis'>
            {options?.[language]?.DivisPartTitle1}
          </span>
          <span className='divis-content-divis'>
            {options?.[language]?.DivisPartTitle2}
            <span className='divis-content-divis-blue'>
              {options?.[language]?.DivisPartTitle3}
            </span>
          </span>
          <span className='divis-content-description'>
            {options?.[language]?.DivisPartDesc}
          </span>
          <button
            className='divis-content-button'
            style={{ cursor: 'pointer' }}
            onClick={e => dispatch(showMobilePopUpAction(true))}
          >
            {options?.[language]?.DivisPartButton}
          </button>
        </div>
        <div className='divis-content-right'>
          <img src={phoneImage} alt='phone image' />
        </div>
      </div>
    </div>
  )
}

export default DivisPart
