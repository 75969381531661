import React, { useEffect } from 'react'
import './App.css'
import Home from './Pages/Home'
import RestourantsOtellsActivities from './Pages/RestourantsOtellsActivities'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import Policy from './Pages/Policy'
import AzIcon from './icons8-azerbaijan-100.png'
import RuIcon from './icons8-russian-federation-100.png'
import EngIcon from './icons8-english-100.png'
import { useDispatch } from 'react-redux'
import { setSearchType } from './redux/action'

const countries = [
  { value: 'AZ', label: 'AZ', icon: AzIcon },
  { value: 'RU', label: 'RU', icon: RuIcon },
  { value: 'EN', label: 'EN', icon: EngIcon }
]

function App () {
  const dispatch = useDispatch()
  const location = useLocation()

  const setLanguage = (label, icon) => {
    window.localStorage.setItem('languageLabel', label)
    window.localStorage.setItem('languageIcon', icon)
  }

  useEffect(() => {
    const path = window.location.pathname.slice(1)
    if (['ru', 'en', 'az'].includes(path)) {
      const language = path.toUpperCase()
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        setLanguage(language, icon)
      }
    }
  }, [location.pathname])

  return (
    <div className='App'>
      <Routes>
        <Route
          path='/'
          element={
            <Navigate
              to={localStorage.getItem('languageLabel')?.toLowerCase() || '/az'}
            />
          }
        />
        {['az', 'en', 'ru'].map(lang => (
          <Route
            key={lang}
            path={`/${lang}`}
            element={<Home />}
            action={e => {
              return dispatch(setSearchType(0))
            }}
          />
        ))}
        <Route path='/gedisler/*' element={<Gedis dispatch={dispatch} />} />
        <Route
          path='/restoranlar/*'
          element={<Restoran dispatch={dispatch} />}
        />
        <Route
          path='/masinlar/*'
          element={<Car dispatch={dispatch} />}
        />
        <Route path='/oteller/*' element={<Otel dispatch={dispatch} />} />
        <Route
          path='/fealiyyetler/*'
          element={<Fealiyyet dispatch={dispatch} />}
        />
        <Route path='/istifade' element={<Policy />} />
        <Route
          path='/*'
          element={
            <Navigate
              to={localStorage.getItem('languageLabel')?.toLowerCase() || '/az'}
            />
          }
        />
      </Routes>
      {/* <MessengerCustomerChat pageId='116382458093697' appId='629494985935575' /> */}
    </div>
  )
}

function Gedis ({ dispatch }) {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const path = window.location.pathname.split('/')[2]
    if (['ru', 'az', 'en'].includes(path)) {
      const language = path.toUpperCase()
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    } else {
      navigate('/gedisler/az')
    }
    dispatch(setSearchType(0))
  }, [location.pathname, dispatch])

  return <Home />
}
function Car ({ dispatch }) {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const path = window.location.pathname.split('/')[2]
    if (['ru', 'az', 'en'].includes(path)) {
      const language = path.toUpperCase()
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    } else {
      navigate('/masinlar/az')
      const language = 'AZ'
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    }
    dispatch(setSearchType(1))
  }, [location.pathname, dispatch])

  return <Home />
}
function Restoran ({ dispatch }) {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const path = window.location.pathname.split('/')[2]
    if (['ru', 'az', 'en'].includes(path)) {
      const language = path.toUpperCase()
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    } else {
      navigate('/restoranlar/az')
      const language = 'AZ'
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    }
    dispatch(setSearchType(2))
  }, [location.pathname, dispatch])

  return <RestourantsOtellsActivities />
}
function Otel ({ dispatch }) {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const path = window.location.pathname.split('/')[2]
    if (['ru', 'az', 'en'].includes(path)) {
      const language = path.toUpperCase()
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    } else {
      navigate('/oteller/az')
    }
    dispatch(setSearchType(3))
  }, [location.pathname, dispatch])

  return <RestourantsOtellsActivities />
}
function Fealiyyet ({ dispatch }) {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const path = window.location.pathname.split('/')[2]
    if (['ru', 'az', 'en'].includes(path)) {
      const language = path.toUpperCase()
      const icon = countries.find(country => country.value === language)?.icon
      if (icon) {
        window.localStorage.setItem('languageLabel', language)
        window.localStorage.setItem('languageIcon', icon)
      }
    } else {
      navigate('/fealiyyetler/az')
    }
    dispatch(setSearchType(4))
  }, [location.pathname, dispatch])

  return <RestourantsOtellsActivities />
}

export default App
