import React, { useEffect, useState } from 'react'
import './Header.css'
import HeaderLogo from '../../img/GezzV2.png'
import { useNavigate } from 'react-router-dom'
import Select, { components } from 'react-select'
import AzIcon from './icons8-azerbaijan-100.png'
import RuIcon from './icons8-russian-federation-100.png'
import EngIcon from './icons8-english-100.png'
import profileIcon from './profileIcon.svg'
import dropIcon from './dropIcon.svg'
import { options } from '../../constants'
import { useDispatch } from 'react-redux'
import { showMobilePopUpAction } from '../../redux/action'
const countries = [
  { value: 'AZ', label: 'AZ', icon: AzIcon, name: 'Azərbaycan dili' },
  { value: 'EN', label: 'EN', icon: EngIcon, name: 'English' },
  { value: 'RU', label: 'RU', icon: RuIcon, name: 'Русский' }
]
const Header = () => {
  const [rotation, setRotation] = useState(0)
  const [loginSignup, setLoginSignup] = useState(false)
  const [headerActive, setHeaderActive] = useState(false)
  const [navBg, setNavBg] = useState(false)
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false)
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  const [selectedCountry, setSelectedCountry] = useState(
    window.localStorage.getItem('languageLabel') &&
      window.localStorage.getItem('languageIcon')
      ? {
          value: window.localStorage.getItem('languageLabel'),
          label: window.localStorage.getItem('languageLabel'),
          icon: window.localStorage.getItem('languageIcon'),
          name: window.localStorage.getItem('languageName')
        }
      : countries[0]
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
    setSelectedCountry(
      window.localStorage.getItem('languageLabel') &&
        window.localStorage.getItem('languageIcon')
        ? {
            value: window.localStorage.getItem('languageLabel'),
            label: window.localStorage.getItem('languageLabel'),
            icon: window.localStorage.getItem('languageIcon'),
            name: window.localStorage.getItem('languageName')
          }
        : countries[0]
    )
  }, [localStorage.getItem('languageLabel')])
  useEffect(() => {
    setLanguage(
      window.location.pathname === '/'
        ? localStorage.getItem('languageLabel')
          ? localStorage.getItem('languageLabel')
          : 'AZ'
        : window.location.pathname
            .split('/')
            [window.location.pathname.split('/').length - 1].toUpperCase()
            .replace('/', '')
    )
  }, [])
  useEffect(() => {
    const handleOutsideClick = e => {
      if (isOpenLanguageModal) {
        if (
          !(e.target.closest('.language-modal') || e.target.closest('.headerR'))
        ) {
          setIsOpenLanguageModal(false)
        }
      }
    }
    document.body.addEventListener('click', handleOutsideClick)
    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpenLanguageModal])
  const handleClick = () => {
    setRotation(rotation + 180)
    setLoginSignup(!loginSignup)
  }
  const navigate = useNavigate()
  const gotToHome = () => {
    navigate('/')
    window.location.reload()
  }
  const changeNavBg = () => {
    window.scrollY >= 1 ? setNavBg(true) : setNavBg(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNavBg)
    return () => {
      window.removeEventListener('scroll', changeNavBg)
    }
  }, [])
  const handleChange = value => {
    setSelectedCountry(value)
    window.localStorage.setItem('languageLabel', value.label)
    window.localStorage.setItem('languageIcon', value.icon)
    window.localStorage.setItem('languageName', value.name)
    let newLocation = window.location.pathname
        .replace('/az', `/${value.label.toLowerCase()}`)
        .replace('/ru', `/${value.label.toLowerCase()}`)
        .replace('/en', `/${value.label.toLowerCase()}`)
    navigate(`${newLocation}`)
  }
  const dispatch = useDispatch()
  return (
    <>
      <div className={`header ${navBg ? 'active' : ''}`}>
        <div className='headerContent'>
          <div className='headerleftMobile'></div>
          <div className='headerlogo' onClick={() => gotToHome()}>
            <img id='headerPikopLogo' src={HeaderLogo} alt='header logo' />
          </div>
          <div className='headerR'>
            <button
              style={{ cursor: 'pointer' }}
              onClick={e => dispatch(showMobilePopUpAction(true))}
            >
              {options[language].download}
            </button>
            <div
              className='language-part'
              onClick={() => setIsOpenLanguageModal(!isOpenLanguageModal)}
            >
              <span
                className='language-border'
                style={{
                  fontSize: '16px'
                }}
              >
                {selectedCountry.label}
              </span>
              <img
                style={{
                  transform: isOpenLanguageModal
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                  transitionDuration: '300ms'
                }}
                className='drop-icon'
                src={dropIcon}
                alt='language icon'
              />
              {isOpenLanguageModal && (
                <div
                  className='language-modal'
                  onClick={e => {
                    e.target.className === 'language-modal' &&
                      setIsOpenLanguageModal(false)
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#333',
                      marginBottom: '10px'
                    }}
                  >
                    {options[language].language}
                  </span>
                  {countries?.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className={`language ${
                          selectedCountry.value === el.value ? 'active' : ''
                        }`}
                        style={{
                          marginTop: index === 0 ? '10px' : '0px'
                        }}
                        onClick={() => handleChange(el)}
                      >
                        <img src={el.icon} alt={el.name} />
                        <span
                          style={{
                            fontSize: '14px'
                          }}
                        >
                          {el.name}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            <img
              className='profile-icon'
              src={profileIcon}
              alt='profile icon '
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
