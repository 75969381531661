import React, { useEffect, useState } from 'react'
import './HowItsWork.css'
import phoneImage from './phoneImage.png'
import GooglePlayIcon from './GooglePlayIcon.png'
import AppStoreIcon from './AppStoreIcon.png'
import { options } from '../../constants'

const HowItsWork = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='howitswork-part'>
      <div className='howitswork-content'>
        <div className='howitswork-content-title'>
          <span>{options?.[language]?.howItsWorkTitle}</span>
        </div>
        <div className='howitswork-content-mini-desc'>
          <span>{options?.[language]?.howItsWorkDesc}</span>
        </div>
        <div className='howitswork-content-desc'>
          <div className='howitswork-content-desc-left'>
            {options?.[language]?.howItsWorkCards?.map((el, index) => {
              return (
                <div className='howitswork-content-desc-left-card' key={index}>
                  <span
                    className='howitswork-content-desc-left-card-number'
                    style={{
                      opacity: index !== 0 ? '0.3' : '1'
                    }}
                  >
                    {el.number}
                  </span>
                  <div>
                    <span className='howitswork-content-desc-left-card-title'>
                      {el.title}
                    </span>
                    <br />
                    <span className='howitswork-content-desc-left-card-desc'>
                      {el.description}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='howitswork-content-desc-right'>
            <img src={phoneImage} alt='phone image' />
          </div>
        </div>
        <div className='howitswork-content-footer'>
          <a
            href='https://play.google.com/store/apps/details?id=com.bidyol&hl=az&gl=AZ'
            target='_blank'
            rel='tag'
          >
            <img src={GooglePlayIcon} alt='google play' />
          </a>
          <a
            href='https://apps.apple.com/az/app/gezz/id6456039328'
            target='_blank'
            rel='tag'
          >
            <img src={AppStoreIcon} alt='app store' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default HowItsWork
