import React, { useEffect, useRef, useState } from 'react'
import './FeedbackPart.css'
import LeftArrow from './backv2.png'
import RightArrow from './forward.png'
import StarsIcon from './stars.png'
import { options } from '../../constants'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
// import 'swiper/css/scrollbar';
// import "swiper/swiper-bundle.css";

const FeedbackPart = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='feedpack-part'>
      <div className='feedpack-content'>
        <div className='feedpack-content-title'>
          <span>{options[language].usersThrouts}</span>
        </div>
        <div className='feedpack-content-feedbacks'>
          <Swiper
            modules={[Autoplay, Navigation]}
            speed={2000}
            navigation={true}
            observer={true}
            observeParents={true}
            autoplay={{
              delay: 1500,
              pauseOnMouseEnter: true
            }}
            loop={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              840: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              902: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              972: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1220: {
                slidesPerView: 4,
                spaceBetween: 31
              }
            }}
            scrollbar={{ draggable: true }}
          >
            {options[language]?.feedbacks?.map((item, index) => (
              <SwiperSlide key={index} style={{
                backgroundColor:"white",
                // width:'302px',
                display:'flex',
                justifyContent:'center'
              }}>
                <div
                  className='feedpack-content-feedbacks-card'
                  key={item.id}
                  style={{
                    backgroundColor: 'white'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <img
                      style={{
                        height: '18px'
                      }}
                      src={StarsIcon}
                      alt='stars'
                    />
                    <span className='date-feedbacks'>{item.date}</span>
                  </div>
                  <span className='desc-feedbacks'>{item.desc}</span>
                  <div
                    style={{
                      display: 'flex',
                      gap: '15px',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#B5CDF4'
                      }}
                    >
                      {item.name[0]}
                    </div>
                    {item.name}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default FeedbackPart
