import React, { useEffect, useState } from 'react'
import './AdvantagesPart.css'
// import firstImage from './firstImage.png'
// import secondImage from './secondImage.png'
import { options } from '../../constants'



const AdvantagesPart = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='advantages-part'>
      <div className='advantages-content'>
        <div className='advantages-content-title'>
          <span>{options?.[language]?.advantagesTitle}</span>
        </div>
        <div className='advantages-content-description'>
          <span>
          {options?.[language]?.advantagesDesc}
          </span>
        </div>
        <div className='advantages-content-cards'>
          {options?.[language]?.advantagesCards?.map((el, index) => {
            return (
              <div className='advantages-content-card' key={index}>
                <div className='advantages-content-card-image'>
                  <img src={el.image} alt={el.person} />
                </div>
                <div className='advantages-content-card-description'>
                  <span
                    style={{
                      backgroundImage:
                        'linear-gradient(90deg, #82B1FF 0%, #005FFB 100%)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                      fontSize: '16px'
                    }}
                  >
                    {el.person}
                  </span>
                  <span
                    style={{
                      color: '#1B183F',
                      fontSize: '20px',
                      textAlign: 'end',
                      lineHeight: '22px',
                      marginTop: '15px',
                      height:'70px'
                    }}
                  >
                    {el.desc}
                  </span>
                  <span>
                    <span
                    style={{
                      color: '#545353',
                      fontSize: '32px',
                      textAlign: 'end',
                      fontFamily:'Poppins, Montserat, sans-serif'
                    }}
                    
                    >{el.price}</span>
                    <span
                    style={{
                      color: '#545353',
                      fontSize: '16px',
                      textAlign: 'end',
                      fontFamily:'Poppins, Montserat, sans-serif'
                    }}
                    >{el.currency}</span>
                  </span>
                  <span
                    style={{
                      color: '#545353',
                      fontSize: '14px',
                      textAlign: 'end',
                      marginTop:'-7px'
                    }}>{el.priceDesc}</span>
                  <span
                    style={{
                      color: '#1B183F',
                      fontSize: '18px',
                      textAlign: 'end',
                      marginTop:'30px'
                    }}>{el.more}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AdvantagesPart
