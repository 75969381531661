import React, { useEffect, useState } from 'react'
import './AppDownloadPopup.css'
import { options } from '../../constants'
import gezzApp from '../../img/Gezz_Hs_iPhone 14.png'
import gezzAppIcon from '../../img/Gezz_1024.png'
import googleplay from './playStore.svg'
import appStore from './appStore.svg'
import gezzLogo from './gezzLogo.svg'
import { useDispatch } from 'react-redux'
import { showMobilePopUpAction } from '../../redux/action'
import closeIcon from './close.svg'

const AppDownloadPopup = () => {
  const dispatch = useDispatch()
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div
      className='appdownload-part'
      onClick={e =>
        e.target.className === 'appdownload-part' &&
        dispatch(showMobilePopUpAction(false))
      }
    >
      <div className='appdownload-component'>
      <img className='close-icon' onClick={() => dispatch(showMobilePopUpAction(false))} src={closeIcon} alt='close icon'/>
        <div className='appdownload-component-left'>
          <img src={gezzLogo} alt='gezz logo' />
        </div>
        <div className='appdownload-component-right'>
          <span style={{
            fontSize:'36px',
            fontWeight:'600'
          }}>{options?.[language]?.carPoolingButton}</span>
          <span style={{
            fontSize:'24px',
            fontWeight:'500'
          }}>{options?.[language]?.sloqan}</span>
          <div style={{
            display:'flex',
            gap:'10px',
             marginTop:'10px'
          }}>
            <a
              href='https://play.google.com/store/apps/details?id=com.bidyol&hl=az&gl=AZ'
              target='_blank'
              rel='tag'
            >
              <img src={googleplay} alt='play store' />
            </a>
            <a
              href='https://apps.apple.com/az/app/gezz/id6456039328'
              target='_blank'
              rel='tag'
            >
              <img src={appStore} alt='app store' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppDownloadPopup
