import React, { useState, useRef, useEffect } from "react";
import "./Searchbar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import BackgroundVideo from "../../img/gezz_bg1.mov";
import CarVideo from "../../img/Pikop.gif";
import { options } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchType,
  setSearchValue,
  showMobilePopUpAction,
} from "../../redux/action";
import { useNavigate } from "react-router-dom";
const types = [
  "gedisler",
  "masinlar",
  "restoranlar",
  "oteller",
  "fealiyyetler",
];
const Searchbar = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const videoRef = useRef(null);
  const searchType = useSelector((state) => state.searchType);
  const searchValue = useSelector((state) => state.searchValue);
  const [makeReadOnly, setMakeReadOnly] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("languageLabel")
      ? localStorage.getItem("languageLabel")
      : "AZ"
  );
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  if (isVideoLoaded) {
    videoRef.current.playbackRate = 1;
  }
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };
  useEffect(() => {
    setLanguage(
      localStorage.getItem("languageLabel")
        ? localStorage.getItem("languageLabel")
        : "AZ"
    );
  }, [localStorage.getItem("languageLabel")]);
  const handleVideoLoad2 = () => {
    const car = document.querySelector(".myVideo2");
    const searchPart = document.querySelector(".searchbar");
    car.style.bottom = 120 + "px";
    searchPart.addEventListener("mouseover", (event) => {
      var x = event.clientX;
      car.style.transition = "all 0.5s";
      car.style.left = parseInt(x) - 125 + "px";
    });
    document
      .querySelector(".searchButton")
      .addEventListener("click", (event) => {
        car.style.transition = "all 4s";
        car.style.left = 2700 + "px";
      });
  };
  const goToAppDownload = (e) => {
    dispatch(showMobilePopUpAction(true));
  };
  const inputRef = useRef(null);
  const navigate = useNavigate();
  return (
    <div className="searchbar">
      <div className="animationPart">
        <div className="myVideo">
          <video
            ref={videoRef}
            id="myVideo"
            onLoadedMetadata={handleVideoLoad}
            autoPlay
            loop
            muted
          >
            <source src={BackgroundVideo} type="video/mp4" />
          </video>
        </div>
        <div className="myVideo2">
          <img
            id="myVideo2"
            src={CarVideo}
            onLoad={(e) => handleVideoLoad2()}
            alt="carGif"
          />
        </div>
      </div>
      <div className="searchPart-div">
        <div className="searchTypePart searchTypePart-desktop">
          <div className="searchTypePart-content">
            {options[language].searchTypes.map((el, index) => {
              return (
                <div
                  className={`searchTypePart-content-div ${
                    searchType === index ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    dispatch(setSearchType(index));
                    dispatch(setSearchValue(""));
                    window.history.pushState(
                      {},
                      "",
                      `/${types[index]}/${language.toLowerCase()}`
                    );
                  }}
                >
                  <img
                    src={el.image[searchType === index ? 1 : 0]}
                    style={{
                      height: index === 1 ? "16px" : "",
                    }}
                    alt={el.name}
                  />
                  <span>{el.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="searchTypePart-mobile">
          <div className="searchTypePart-mobile-content">
            {options[language].searchTypes.map((el, index) => {
              return (
                <div
                  key={index}
                  className={`searchTypePart-mobile-content-div ${
                    searchType === index ? "active" : ""
                  }`}
                  style={{
                    marginLeft: index === 0 ? "5%" : "0px",
                  }}
                  onClick={() => {
                    dispatch(setSearchType(index));
                    dispatch(setSearchValue(""));
                    window.history.pushState(
                      {},
                      "",
                      `/${types[index]}/${language.toLowerCase()}`
                    );
                  }}
                >
                  <img
                    src={el.image[searchType !== index ? 1 : 0]}
                    style={{
                      height: index === 1 ? "16px" : "",
                    }}
                    alt={el.name}
                  />
                  <span>{el.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="searchPart" id="searchPartId" onClick={(e) => searchType === 1 && goToAppDownload(e)}>
          {searchType === 0 || searchType === 1 ? (
            <>
              <div id="inputFrom2" onClick={(e) => goToAppDownload(e)}>
                <svg
                  viewBox="0 0 24 24"
                  fill="#005ffb"
                  height="1em"
                  width="1em"
                >
                  <path d="M5.64 16.36a9 9 0 1112.72 0l-5.65 5.66a1 1 0 01-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 10-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
                <input
                  id="inputFrom"
                  type="text"
                  placeholder={searchType === 0  ? options[language].from : options[language].fromCar}
                  readOnly={true}
                />
              </div>
              {searchType === 0 ? (
                <>
                  <hr className="isMobileHr" />
                  <div id="inputTo2" onClick={(e) => goToAppDownload(e)}>
                    <hr id="hr1" />
                    <svg
                      fill="none"
                      stroke="#005ffb"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M21 3l-6.5 18a.55.55 0 01-1 0L10 14l-7-3.5a.55.55 0 010-1L21 3" />
                    </svg>
                    <input
                      id="inputTo"
                      type="text"
                      placeholder={options[language].to}
                      readOnly={true}
                    />
                  </div>
                  <hr className="isMobileHr" />
                  <div id="datePickerId2" onClick={(e) => goToAppDownload(e)}>
                    <hr id="hr2" />
                    <svg
                      viewBox="0 0 512 512"
                      fill="#005ffb"
                      height="1em"
                      width="1em"
                    >
                      <path d="M480 128a64 64 0 00-64-64h-16V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 00368 48v16H144V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 00112 48v16H96a64 64 0 00-64 64v12a4 4 0 004 4h440a4 4 0 004-4zM32 416a64 64 0 0064 64h320a64 64 0 0064-64V179a3 3 0 00-3-3H35a3 3 0 00-3 3zm344-208a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm-80-80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm-80-80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm-80-80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24z" />
                    </svg>
                    <DatePicker
                      id="datePickerId"
                      dateFormat="dd.MM.yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      yearItemNumber={true}
                      readOnly={true}
                    />
                  </div>
                  <hr className="isMobileHr" />
                  <div id="inputPersonNum2" onClick={(e) => goToAppDownload(e)}>
                    <hr id="hr3" />
                    <svg
                      viewBox="0 0 16 16"
                      fill="#005ffb"
                      height="1em"
                      width="1em"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.5 5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm.061 3.073a4 4 0 10-5.123 0 6.004 6.004 0 00-3.431 5.142.75.75 0 001.498.07 4.5 4.5 0 018.99 0 .75.75 0 101.498-.07 6.005 6.005 0 00-3.432-5.142z"
                      />
                    </svg>
                    <input
                      id="inputPersonNum"
                      type="number"
                      defaultValue={1}
                      readOnly={true}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div
                id="inputTo2"
                className="othertype secondinput"
                onClick={() => inputRef?.current?.focus()}
              >
                {/* <hr id='hr1' /> */}
                <input
                  ref={inputRef}
                  id="inputTo"
                  type="text"
                  style={{
                    paddingLeft: "10px",
                  }}
                  onClick={(e) => setMakeReadOnly(false)}
                  readOnly={makeReadOnly}
                  placeholder={options[language].searchNames[searchType]}
                  value={searchValue}
                  onChange={(e) => dispatch(setSearchValue(e.target.value))}
                />
              </div>
            </>
          )}
          <button
            className="searchButton"
            style={{ lineHeight: "1" }}
            onClick={(e) => {
              searchType !== 0 && searchType !== "0"
                ? document?.getElementById(`cards-pard-id`)?.scrollIntoView({
                    behavior: "smooth",
                  }) && setMakeReadOnly(true)
                : goToAppDownload(e) && setMakeReadOnly(true);
            }}
          >
            {searchType === 0 || searchType === 1
              ? options[language].download
              : options[language].search}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
