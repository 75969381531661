import React, { useEffect, useState } from 'react'
import Searchbar from '../Components/Searchbar/Searchbar'
import PopupMobile from '../Components/PopupM/PopupMobile'
import AppDownloadPopup from '../Components/AppDownloadPopup/AppDownloadPopup'
import Header from '../Components/Header/Header'
import CardsPart from '../Components/CardsPart/CardsPart'
import Footer from '../Components/Footer/Footer'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { metaTagsValues } from '../metaTags'

const RestourantsOtellsActivities = () => {
  const navigate = useNavigate()
  const types = ['gedisler', 'restoranlar', 'oteller', 'fealiyyetler']
  const showMobilePopUp = useSelector(state => state.showMobilePopUp)
  const searchType = useSelector(state => state.searchType)
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  useEffect(() => {
    if (types.includes(window.location.pathname.split('/')[1])) {
      let lng = window.location.pathname.split('/')[2]?.toUpperCase()
      let typeİndex = types.findIndex(
        type => type === window.location.pathname.split('/')[1]
      )
      let ogTitle = document.getElementById('ogTitle')
      let ogDescription = document.getElementById('ogDescription')
      ogTitle.setAttribute('content', metaTagsValues?.[lng]?.title?.[typeİndex])
      ogDescription.setAttribute(
        'content',
        metaTagsValues[lng].description[typeİndex]
      )
      document.title = metaTagsValues[lng].title[typeİndex]
      document
        .getElementsByTagName('meta')
        .namedItem('description')
        .setAttribute('content', metaTagsValues[lng].description[typeİndex])
      document
        .getElementsByTagName('meta')
        .namedItem('keywords')
        .setAttribute('content', metaTagsValues[lng].keywords[typeİndex])
    }
  }, [window.location.pathname])
  useEffect(() => {
    if (
      (searchType === 0 || searchType == '0') &&
      window.location.pathname.includes('gedis')
    ) {
      navigate(`/gedisler/${language.toLowerCase()}`)
    }
  }, [searchType])
  return (
    <div className='home'>
      <Header />
      <Searchbar />
      {searchType !== 0 && <CardsPart searchType={searchType} />}
      <PopupMobile />
      {showMobilePopUp === true && <AppDownloadPopup />}
      <Footer />
    </div>
  )
}

export default RestourantsOtellsActivities
