export function showMobilePopUpAction (value) {
  return {
    type: 'SHOW_MOBILE_POPUP',
    payload: {
      value: value
    }
  }
}
export function setSearchType (value) {
  return {
    type: 'SET_SEARCH_TYPE',
    payload: {
      value: value
    }
  }
}
export function setSearchValue (value) {
  return {
    type: 'SET_SEARCH_VALUE',
    payload: {
      value: value
    }
  }
}
export function setPage (value) {
  return {
    type: 'SET_PAGE',
    payload: {
      value: value
    }
  }
}
