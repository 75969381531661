import React, { useEffect, useState } from 'react'
import './CounterPart.css'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { options } from '../../constants'

const CounterPart = () => {
  const [timeLast,] = useState(1719292961000)
  const [timeNow,] = useState(Date.now())
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  const [focus, setFocus] = React.useState(false)
  return (
    <div className='counter-part'>
      <div className='counter-content'>
        <span className='counter-content-title'>
          {options?.[language]?.everyDayGrow}
        </span>
        <span className='counter-content-count'>
          <CountUp
            start={focus ? 20000 : null}
            end={25187 + Math.round((timeNow - timeLast) / (600000))}
            duration={3}
            redraw={false}
          >
            {({ countUpRef }) => (
              <VisibilitySensor
                onChange={isVisible => {
                  if (isVisible) {
                    setFocus(true)
                  }
                }}
              >
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
        </span>
        <span className='counter-content-users'>
          {options?.[language]?.usersCountName}
        </span>
      </div>
    </div>
  )
}

export default CounterPart
