import React, { useEffect, useState } from 'react'
import './CardsPart.css'
import { options } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import Card from './Card'
import Pagination from '@mui/material/Pagination'
import { setPage } from '../../redux/action'

const CardsPart = ({ searchType }) => {
  const dispatch = useDispatch()
  const searchValue = useSelector(state => state.searchValue)
  const page = useSelector(state => state.page)
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  const [filteredCards, setFilteredCards] = useState(
    searchType === 2
      ? options[language].restourantsCards
      : searchType === 3
      ? options[language].otelsCards
      : searchType === 4
      ? options[language].activitiesCards
      : []
  )
  const [pagesCount, setPageCounts] = useState(
    Math.ceil(
      searchType === 2
        ? options[language].restourantsCards.length / 6
        : searchType === 3
        ? options[language].otelsCards.length / 6
        : searchType === 4
        ? options[language].activitiesCards.length / 6
        : []
    )
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])

  useEffect(() => {
    dispatch(setPage(1))
    if (searchValue.replaceAll(' ', '') === '') {
      setFilteredCards(
        searchType === 2
          ? options[language].restourantsCards
          : searchType === 3
          ? options[language].otelsCards
          : searchType === 4
          ? options[language].activitiesCards
          : []
      )
      setPageCounts(
        Math.ceil(
          searchType === 2
            ? options[language].restourantsCards.length / 6
            : searchType === 3
            ? options[language].otelsCards.length / 6
            : searchType === 4
            ? options[language].activitiesCards.length / 6
            : 0
        )
      )
    } else {
      setFilteredCards(
        (searchType === 2
          ? options[language].restourantsCards
          : searchType === 3
          ? options[language].otelsCards
          : searchType === 4
          ? options[language].activitiesCards
          : []
        ).filter(
          el =>
            el.name
              .replaceAll(' ', '')
              .toUpperCase()
              .includes(searchValue.replaceAll(' ', '').toUpperCase()) ||
            el.phone
              .replaceAll(' ', '')
              .toUpperCase()
              .includes(searchValue.replaceAll(' ', '').toUpperCase()) ||
            el.city === Number(searchValue.replaceAll(' ', '')) ||
            options[language].cities
              .filter(el =>
                el
                  .replaceAll(' ', '')
                  .toUpperCase()
                  .includes(searchValue.replaceAll(' ', '').toUpperCase())
              )
              .includes(options[language].cities[el.city])
        )
      )
      setPageCounts(
        Math.ceil(
          (searchType === 2
            ? options[language].restourantsCards
            : searchType === 3
            ? options[language].otelsCards
            : searchType === 4
            ? options[language].activitiesCards
            : []
          ).filter(
            el =>
              el.name
                .replaceAll(' ', '')
                .toUpperCase()
                .includes(searchValue.replaceAll(' ', '').toUpperCase()) ||
              el.phone
                .replaceAll(' ', '')
                .toUpperCase()
                .includes(searchValue.replaceAll(' ', '').toUpperCase()) ||
              el.city === Number(searchValue.replaceAll(' ', '')) ||
              options[language].cities
                .filter(el =>
                  el
                    .replaceAll(' ', '')
                    .toUpperCase()
                    .includes(searchValue.replaceAll(' ', '').toUpperCase())
                )
                .includes(options[language].cities[el.city])
          ).length / 6
        )
      )
    }
  }, [searchValue])
  useEffect(() => {
    dispatch(setPage(1))
    setFilteredCards(
      searchType === 2
        ? options[language].restourantsCards
        : searchType === 3
        ? options[language].otelsCards
        : searchType === 4
        ? options[language].activitiesCards
        : []
    )
    setPageCounts(
      Math.ceil(
        searchType === 2
          ? options[language].restourantsCards.length / 6
          : searchType === 3
          ? options[language].otelsCards.length / 6
          : searchType === 4
          ? options[language].activitiesCards.length / 6
          : 0
      )
    )
  }, [searchType])
  return (
    <>
      <div
        className='cards-part'
        id='cards-pard-id'
        style={{
          paddingTop: '15px'
        }}
      >
        <div className='cards-content'>
          {(searchValue.replaceAll(' ', '') === ''
            ? filteredCards
            : filteredCards.filter(
                el =>
                  el.name
                    .replaceAll(' ', '')
                    .toUpperCase()
                    .includes(searchValue.replaceAll(' ', '').toUpperCase()) ||
                  el.phone
                    .replaceAll(' ', '')
                    .toUpperCase()
                    .includes(searchValue.replaceAll(' ', '').toUpperCase()) ||
                  el.city === Number(searchValue.replaceAll(' ', '')) ||
                  options[language].cities
                    .filter(el =>
                      el
                        .replaceAll(' ', '')
                        .toUpperCase()
                        .includes(searchValue.replaceAll(' ', '').toUpperCase())
                    )
                    .includes(options[language].cities[el.city])
              )
          )
            .slice((page - 1) * 6, (page - 1) * 6 + 6)
            .map((el, index) => {
              return <Card cardData={el} key={index} />
            })}
        </div>
        {pagesCount !== 0 ? (
          <Pagination
            count={pagesCount}
            page={page}
            showFirstButton
            showLastButton
            onChange={(e, value) => {
              dispatch(setPage(value))
              document?.getElementById('cards-pard-id')?.scrollIntoView({
                behavior: 'smooth'
              })
            }}
          />
        ) : (
          options[language].searchNamesNotFound[searchType]
        )}
      </div>
    </>
  )
}

export default CardsPart