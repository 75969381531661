const initialState = {
  showMobilePopUp: false,
  searchType: 0,
  searchValue: '',
  page: 1
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_MOBILE_POPUP':
      return {
        ...state,
        showMobilePopUp: action.payload.value
      }
    case 'SET_SEARCH_TYPE':
      return {
        ...state,
        searchType: action.payload.value
      }
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload.value
      }
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload.value
      }
    default:
      return state
  }
}
