import React, { useEffect, useState } from 'react'
import './SecurityPart.css'
import { options } from '../../constants'
import { useDispatch } from 'react-redux'
import { showMobilePopUpAction } from '../../redux/action'

const SecurityPart = () => {
  const dispatch= useDispatch()
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='security-part'>
      <div className='security-content'>
        <div className='security-content-title'>
            <span>{options?.[language]?.yourSecurityTile}</span>
        </div>
        <div className='security-content-description'>
        {options?.[language]?.yourSecurityDesc}
        </div>
        <div className='security-content-button'>
            <button style={{cursor:'pointer'}} onClick={() => dispatch(showMobilePopUpAction(true))}>{options?.[language]?.yourSecurityMore}</button>
        </div>
      </div>
    </div>
  )
}

export default SecurityPart
